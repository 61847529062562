<template>
<Layout :title="'이용약관'">
  <div class="container flex flex-col term-list-wrapper">
    <div class="term-section" v-for="item in data" :key="item.id">
      <h3 :class="['term-title', `priority-${item.priority}`]">
        {{ item.title }}
      </h3>
      <p class="term-content">
        {{ item.contents }}
      </p>
    </div>
  </div>
</Layout>
</template>

<script>
import Layout from '@/components/Layout.vue';
import services from '@/services';

export default {
  name: 'Term',
  components: {
    Layout,
  },
  data() {
    return {
      data: Object
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const category = await services.termCategory();
      if (category.length > 0) {
        const data = await services.terms({categoryId: category[0].id});
        this.data = data;
        console.log(data);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.term-list-wrapper {
  margin-top: 40px;
  margin-bottom: 80px;
  min-height: 350px;

  .term-section {
    margin-bottom: 20px;

    .term-title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.16px;
      text-align: left;
      color: #333;
    }

    .term-content {
      margin-top: 10px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.16px;
      text-align: left;
      color: #444;
    }
  }
}

@media (max-width: 639px) {
  .term-list-wrapper {
    .term-section {
      .term-title {
        font-size: 14px;
      }
      .term-content {
        font-size: 12px;
      }
    }
  }
}
</style>